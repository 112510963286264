<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <router-link :to="{ name: 'autoskola-vozila-kreiraj' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t("DodajVozilo") }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="pb-1">
        <div>
          <b-card-title>{{ $t('Vozila') }}</b-card-title>
        </div>
        <b-button-group size="sm">
          <b-button
            :variant="filter === 'aktivna' ? 'primary' : 'outline-primary'"
            @click="filter='aktivna'"
          >
            {{ $t("Aktivna") }}
          </b-button>
          <b-button
            :variant="filter === 'neaktivna' ? 'primary' : 'outline-primary'"
            @click="filter = 'neaktivna'"
          >
            {{ $t("Neaktivna") }}
          </b-button>
          <b-button
            :variant="filter === 'sve' ? 'primary' : 'outline-primary'"
            @click="filter = 'sve'"
          >
            {{ $t("SvaVozila") }}
          </b-button>
        </b-button-group>
      </b-card-header>
      <b-card-body>
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-vozila-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
                <b-button
                  v-if="data.item.aktivan"
                  variant="danger"
                  @click="modalBrisanje(data.item.id, `${data.item.marka} ${data.item.tip} ${data.item.regbroj}`)"
                >
                  {{ $t("Deaktiviraj") }}
                </b-button>
                <b-button
                  v-if="!data.item.aktivan"
                  variant="success"
                  @click="modalAktiviranje(data.item.id, `${data.item.marka} ${data.item.tip} ${data.item.regbroj}`)"
                >
                  {{ $t("Aktiviraj") }}
                </b-button>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  hover
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr
                      :variant="row.item.istice_registracija ? 'warning' : (row.item.istekla_registracija ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaRegistracije') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_registracije }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_sestomesecni_tehnicki ? 'warning' : (row.item.istekao_sestomesecni_tehnicki ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaSestomesecnogTehnickog') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_sestomesecnog_tehnickog_pregleda }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_redovan_servis ? 'warning' : (row.item.istekao_redovan_servis ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('RedovanServisNa') }} {{ row.item.km_redovan_servis }}
                      </b-th>
                      <b-td>{{ $t('StanjeNaPutometru') }} {{ row.item.stanje_putometar }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_prva_pomoc ? 'warning' : (row.item.istekla_prva_pomoc ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPrvePomoci') }}
                      </b-th>
                      <b-td>{{ row.item.prva_pomoc ? row.item.datum_isteka_prve_pomoci : $t('NemaPrvuPomoc') }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_pp_aparat ? 'warning' : (row.item.istekao_pp_aparat ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPPAparata') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_pp_aparata }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_servis_tahografa ? 'warning' : (row.item.istekao_servis_tahografa ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumServisaTahografa') }}
                      </b-th>
                      <b-td>{{ row.item.datum_servisa_tahografa }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_preuzimanje_podataka_sa_digitalnog_tahografa ? 'warning' : (row.item.isteklo_preuzimanje_podataka_sa_digitalnog_tahografa ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumPreuzimanjaPodatakaTahograf') }}
                      </b-th>
                      <b-td>{{ row.item.datum_preuzimanja_podataka_tahograf }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_atest_za_tng ? 'danger' : (row.item.istekao_atest_za_tng ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumAtestaTng') }}
                      </b-th>
                      <b-td>{{ row.item.datum_atesta_tng }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaRezultata') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-deaktiviranje-vozila"
      ref="modal-deaktiviranje-vozila"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Deaktiviraj')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('DeaktivirajVozilo')"
      size="lg"
      @ok="deaktivirajVozilo"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaDeaktivirateVozilo:') }}
        </p>
        <p>
          {{ podaciVozilaZaDeaktivaciju.naziv }}
        </p>
      </div>
    </b-modal>

    <b-modal
      id="modal-aktiviranje-vozila"
      ref="modal-aktiviranje-vozila"
      ok-variant="success"
      cancel-variant="outline-secondary"
      :ok-title="$t('Aktiviraj')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('AktivirajVozilo')"
      size="lg"
      @ok="aktivirajVozilo"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaAktivirateVozilo:') }}
        </p>
        <p>
          {{ podaciVozilaZaDeaktivaciju.naziv }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BCardHeader,
  BCardTitle,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BModal,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BModal,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        'marka',
        'tip',
        { key: 'regbroj', label: 'Registarski broj' },
        { key: 'kategorija', label: this.$i18n.t('Kategorija') },
        { key: 'datum_isteka_registracije', label: this.$i18n.t('KrajRegistracije') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      podaciVozilaZaDeaktivaciju: {},
      filter: 'aktivna',
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    filter: {
      handler() {
        this.pokupiListu() // call it in the context of your component object
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get(`/autoskole-administratori/vozila?filter=${this.filter}`).then(response => {
        this.items = response.data
      })
    },
    modalBrisanje(id, naziv) {
      this.podaciVozilaZaDeaktivaciju = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-deaktiviranje-vozila')
    },
    modalAktiviranje(id, naziv) {
      this.podaciVozilaZaDeaktivaciju = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-aktiviranje-vozila')
    },
    deaktivirajVozilo() {
      this.$http.delete(`/autoskole-administratori/vozila/${this.podaciVozilaZaDeaktivaciju.id}`).then(() => {
        this.$bvModal.hide('modal-deaktiviranje-vozila')
        this.pokupiListu()
      })
    },
    aktivirajVozilo() {
      this.$http.delete(`/autoskole-administratori/vozila/${this.podaciVozilaZaDeaktivaciju.id}`).then(() => {
        this.$bvModal.hide('modal-aktiviranje-vozila')
        this.pokupiListu()
      })
    },
  },
}
</script>
